// Loader.js
import React from 'react';
import './Loader.css'; // Import the CSS file for the loader
import loadImage from "../assets/img/giphy.gif";

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="loader-text">
                Loading<span className="loader-dots">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                </span>
            </div>
            <div className="loading">
                  <img src={loadImage} alt="Loader" />
             </div>
        </div>
    );
};

export default Loader;