import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TestimonialsCarousel from './TestimonialsCarousel';
import LearningTestimonialVideosAllLoad from './LearningTestimonialVideosAllLoad';
import LearningPlacementAlumni from './LearningPlacementAlumni';

const InternshipPlacement = () => {
  const connectwhatsapp = () => {
    const phoneNumber = '+919669787936';
    const message = `Hi.`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank').focus();

  }
  // Initial video data
  const initialVideos = [
    { videoId: 'of0jFUbEPUU' },
    { videoId: 'rbSAcKSqvZk' },
    { videoId: 'FitPyYyOFUw' }
  ];

  // Additional videos to load
  const moreVideos = [
    { videoId: 'jUnHNHH8O4A' },
    { videoId: 'ly1p2PUmHsI' },
    { videoId: '50xTak0Gg0k' },
    { videoId: 'r4mPl5I-JkI' },
    { videoId: 'sG2ncNcbmcA' },
    { videoId: 'zRC6aYdjsic' },
  ];

  // State to manage loaded videos
  const [videos, setVideos] = useState(initialVideos);
  const [allLoaded, setAllLoaded] = useState(false);

  // Function to load more videos
  const loadMoreVideos = () => {
    if (!allLoaded) {
      setVideos([...videos, ...moreVideos]);
      setAllLoaded(true);
    }
  };
  return (
    <>

      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Internship cum Placement</h1>
          <h4 className="text-white">
            Start your career in top startups and companies.
          </h4>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                Home
              </a>
            </li>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                learning
              </a>
            </li>
            <li>CorporateTraining</li>
          </ul> */}
        </div>
      </div>

      <section class="services-area service-area services-padding">
        



        <div class="rs-specialty bg18 pt-170 pb-170 md-pb-185">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="rs-feature">
                  <div class="row">
                    <div class="col-md-6 mt-20 pr-6  md-pb-20">
                      <div class="icon-box-area mb-20">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/1.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                Web Fundamentals (HTML, CSS & JS)
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="icon-box-area purple-bg mb-20">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/3.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                Data Structure & Soft Skills
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div class="icon-box-area blue-bg mb-20">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/2.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                Java, SpringBoot, Hibernate
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div class="col-md-6">
                      <div class="icon-box-area blue-bg mb-20">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/2.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                React JS, Redux
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="icon-box-area pink-bg mb-20">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/4.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                Node JS, Express, MySQL, MongoDB
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div class="icon-box-area mb-20">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/1.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                Python, PHP
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
              <div class="col-lg-6 pl-55 md-pl-15 md-pt-60">
                <div class="sec-title6 mb-30">
                  {/* <div class="sub-text">Course Specialty</div> */}
                  <h2 class="title pb-20">
                    What's so Special About course
                  </h2>
                  {/* <h2 class="title pb-20">
                    What's so Special About Braintech
                  </h2>
                  <div class="desc">
                    Bring to the table win-win survival strategies to ensure dotted proactive domination. At the end of the day, going forward, a new normal that has evolved
                  </div> */}
                </div>
                <ul class="rs-features-list mb-5">
                  <li><i class="fa fa-check"></i><span>For college final years / graduates</span></li>
                  {/* <li><i class="fa fa-check"></i><span>4 months classroom program</span></li>
                  <li><i class="fa fa-check"></i><span>2 months internship experience</span></li> */}
                  {/* <li><i class="fa fa-check"></i><span>Session Timings - Evening After 6pm</span></li> */}
                  {/* <li><i class="fa fa-check"></i><span>Online Instructor led Classes</span></li> */}
                  <li><i class="fa fa-check"></i><span>Live Projects Internship from Day 1</span></li>
                  <li><i class="fa fa-check"></i><span>One-2-one mentorship </span></li>
                  <li><i class="fa fa-check"></i><span>Mock Interviews</span></li>
                  <li><i class="fa fa-check"></i><span>Soft Skills and Aptitude Prep</span></li>
                  <li><i class="fa fa-check"></i><span>100% Placement Guarantee</span></li>
                  <li><i class="fa fa-check"></i><span>Internship and Experiene Certificate</span></li>
                  <li><i class="fa fa-check"></i><span>Pay after placement</span></li>
                  <li><i class="fa fa-check"></i><span>Total Fee - 10,000</span></li>
                </ul>

                <div class="btn-part">
                  <Link class="readon started get-ready3" to="/learning/registration/internshipplacement">Enroll Now</Link>
                </div>

                <div class="btn-part">
                  <Link class="readon learn-more learn-btn mt-4">Call 9669787936 for details and discounts</Link>
                </div>
                <div class="btn-part">
                  <Link class="mt-4" onClick={connectwhatsapp} target="_blank" style={{ backgroundColor: 'green', color: 'white', padding: '10px 20px', textDecoration: 'none', borderRadius: '5px', display: 'inline-block', textAlign: 'center' }}>
                    Connect on Whatsapp
                  </Link>
                </div>

                {/* New Code */}

                <div class="btn-part">
                  <Link class="readon started get-ready3 mt-4" to="/process" >Click For Enrollment Process</Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      <div class="rs-services style3 modify2 pt-100 pb-100 md-pt-75 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            {/* <span class="sub-text gold-color">Services</span> */}
            <h2 class="title title7">
              Placement Batch Plan 2024
            </h2>
          </div>
          <div class="row">
            <div class="col-md-6 mb-20">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="../../assets/images/services/style2/main-img/1.png" alt="" />
                    <img class="hover-img" src="../../assets/images/services/style2/hover-img/1.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Duration</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      6 Months Internship | Placement Post Internship
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-20">
              <div class="services-item pink-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="../../assets/images/services/style2/main-img/2.png" alt="" />
                    <img class="hover-img" src="../../assets/images/services/style2/hover-img/2.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Internship</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Project Works, Live Sessions, Mentor and Doubt Sessions
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-20">
              <div class="services-item aqua-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="../../assets/images/services/style2/main-img/3.png" alt="" />
                    <img class="hover-img" src="../../assets/images/services/style2/hover-img/3.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Assignments and Tests</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Weekly Assignments and Weekend Tests
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-20">
              <div class="services-item paste-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="../../assets/images/services/style2/main-img/4.png" alt="" />
                    <img class="hover-img" src="../../assets/images/services/style2/hover-img/4.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Technologies</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      MERN Stack with Other Tech - Python, PHP, Java, Angular
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 sm-mb-20">
              <div class="services-item purple-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="../../assets/images/services/style2/main-img/5.png" alt="" />
                    <img class="hover-img" src="../../assets/images/services/style2/hover-img/5.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Placement Plan</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Starts from 7th month after 6 month classes
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="services-item green-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="../../assets/images/services/style2/main-img/6.png" alt="" />
                    <img class="hover-img" src="../../assets/images/services/style2/hover-img/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Soft Skills & HR Consulting</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Mock Interview, Soft Skills, Resume Building
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>






      {/* <div class="rs-services style8 pb-120 md-pt-80 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-50">
            <h2 class="title testi-title">
              Placement Batch Modules
            </h2>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-6 md-mb-30">
              <div class="services-item">
                <div class="services-img">
                  <img src="../../assets/images/services/style10/1.png" alt="Services" />
                </div>
                <div class="services-content">
                  <div class="services-title">
                    <h3 class="title"> Module 1 - MERN Stack<br></br>4 Months</h3>
                  </div>
                  <p class="services-txt">
                    HTML, CSS, Bootstrap<br></br>
                    JavaScript<br></br>
                    React<br></br>
                    Node, Express, MySQL, MongoDB

                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 md-mb-30">
              <div class="services-item gray-light-bg">
                <div class="services-img">
                  <img src="../../assets/images/services/style10/2.png" alt="Services" />
                </div>
                <div class="services-content">
                  <div class="services-title">
                    <h3 class="title">Module 2 - Java Stack<br></br>2 Months</h3>
                  </div>
                  <p class="services-txt">
                    Java, SpringBoot, Hibernate<br></br>
                    Angular<br></br>
                    Python Stack<br></br>
                    PHP Stack<br></br>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="services-item pink-bg">
                <div class="services-img">
                  <img src="../../assets/images/services/style10/3.png" alt="Services" />
                </div>
                <div class="services-content">
                  <div class="services-title">
                    <h3 class="title">Module 3 - Placement Ready<br></br>2 Months</h3>
                  </div>
                  <p class="services-txt">
                    Mock Interviews<br></br>
                    Soft Skills Training<br></br>
                    Placement Tests and Assignments<br></br>
                    Tech Internship in Live Project
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div> */}


      <div class="rs-counter style3 gray-color modify2 mb-50 pb-80 pt-100">
        <div class="container">
          <div class="counter-top-area">
            <div class="row">
              <div class="col-lg-3 col-md-6 md-pb-50">
                <div class="counter-list">
                  <div class="counter-text">
                    <div class="count-number">
                      <span class="rs-count plus purple-color">1000</span>
                    </div>
                    <h3 class="title">Learning Participants</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 md-pb-50">
                <div class="counter-list">
                  <div class="counter-text">
                    <div class="count-number">
                      <span class="rs-count blue-color">90%</span>
                    </div>
                    <h3 class="title">Placement Ratio</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 sm-pb-50">
                <div class="counter-list">
                  <div class="counter-text">
                    <div class="count-number">
                      <span class="rs-count pink-color">50+</span>
                    </div>
                    <h3 class="title">Courses</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="counter-list">
                  <div class="counter-text">
                    <div class="count-number">
                      <span class="rs-count paste-color">20+</span>
                    </div>
                    <h3 class="title">Trainers</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <LearningPlacementAlumni/>

      <LearningTestimonialVideosAllLoad/>

      <TestimonialsCarousel />
      {/* <div class="rs-services style8 pt-120 pb-120 md-pt-80 md-pb-80">
        <br></br>
        <div className="container"></div>
        <LearningTestimonialVideos />

      </div> */}

    </>
  )
}

export default InternshipPlacement;
