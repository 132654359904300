import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import tickimage from '../../assets/img/learning/tick.svg';
import YouTubeEmbed from '../shared/YouTubeEmbed';
import LearningTestimonialVideos from './LearningTestimonialVideos';

const CoursePython = () => {
  const connectwhatsapp = () => {
    const phoneNumber = '+919669787936';
    const message = `Hi.`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank').focus();

  }
  // Initial video data
  // const initialVideos = [
  //   { videoId: 'yR1wbqGz06c' },
  //   { videoId: '6stlCkUDG_s' },
  //   { videoId: 'dQw4w9WgXcQ' }
  // ];

  // Additional videos to load
  // const moreVideos = [
  //   { videoId: 'dQw4w9WgXcQ' },
  //   { videoId: 'yR1wbqGz06c' },
  //   { videoId: '6stlCkUDG_s' }
  // ];

  // State to manage loaded videos
  // const [videos, setVideos] = useState(initialVideos);
  // const [allLoaded, setAllLoaded] = useState(false);

  // Function to load more videos
  // const loadMoreVideos = () => {
  //   if (!allLoaded) {
  //     setVideos([...videos, ...moreVideos]);
  //     setAllLoaded(true); 
  //   }
  // };
  return (
    <>

      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Python Full Stack Web Development</h1>
          <h4 className="text-white">
            Learn to build web applications on one of the best tech stacks : Django, Flask, Cherrypy and TurboGears - PYTHON
          </h4>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                Home
              </a>
            </li>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                learning
              </a>
            </li>
            <li>CorporateTraining</li>
          </ul> */}
        </div>
      </div>

      <section class="services-area service-area services-padding">
        {/* <div class="row d-flex justify-content-center">
            <div class="col-lg-12">
              <div class="section-tittle text-center">
                <h2>MERN Full Stack Web Development</h2>
              </div>
            </div>
          </div> */}

        {/* <div class="row">
            <div class="col-lg-12 bg-warning bg-opacity-10 py-4 text-center">
              <h5>Learn to build web applications on one of the best tech stacks : MongoDB, Express.js, React.js and Node.js - MERN stack.</h5>
            </div>
          </div> */}

        <div class="rs-specialty bg18 pt-170 pb-230 md-pb-185">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="rs-feature">
                  <div class="row">
                    <div class="col-md-6 mt-20 pr-6  md-pb-20">
                      <div class="icon-box-area mb-20">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/2.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                Web Fundamentals (HTML, CSS &amp; JS)
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="icon-box-area purple-bg">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/4.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                React JS, Angular, Vue.js
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="icon-box-area blue-bg mb-20">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/1.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                Flask, DJango, FastAPI
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="icon-box-area pink-bg">
                        <div class="box-inner">
                          <div class="icon-area">
                            <a href="#">
                              <img
                                src="../../assets/images/features/icons/3.png"
                                alt=""
                              />
                            </a>
                          </div>
                          <div class="content-part">
                            <h4 class="title">
                              <a href="#">
                                MySQL, PostgreSQL, SQLight
                              </a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 pl-55 md-pl-15 md-pt-60">

                <div class="sec-title6 mb-30">
                  {/* <div class="sub-text">Our Specialty</div> */}
                  <h2 class="title pb-20">
                    What's so Special About course
                  </h2>
                </div>
                <ul class="rs-features-list mb-5">
                  <li><i class="fa fa-check"></i><span>For college students</span></li>
                  <li><i class="fa fa-check"></i> <span>4 months classroom program</span></li>
                  <li><i class="fa fa-check"></i><span>2 months internship experience</span></li>
                  <li><i class="fa fa-check"></i><span>Session Timings - Evening After 6pm</span></li>
                  <li><i class="fa fa-check"></i><span>Online Instructor led  Classes</span></li>
                  <li><i class="fa fa-check"></i><span>Projects based Learning</span></li>
                  <li><i class="fa fa-check"></i><span>One-2-one mentorship / Doubt Sessions</span></li>
                  <li><i class="fa fa-check"></i><span>Training Certificate + Internship / Placement Opportunity</span></li>
                  <li><i class="fa fa-check"></i><span>Pay in monthly EMIs after classes</span></li>
                  <li><i class="fa fa-check"></i><span>New Batch starting from August</span></li>
                  <li><i class="fa fa-check"></i><span>Total Fee - 10,000</span></li>
                  <li><i class="fa fa-check"></i><span>Discount - Upto 50%</span></li>
                  <li><i class="fa fa-check"></i><span>Payment Plan - 5 EMIs</span></li>
                  <li><i class="fa fa-check"></i><span>EMIs starting from 2000 Per Month</span></li>
                </ul>

                <div class="btn-part">
                  <Link class="readon started get-ready3" to="/learning/registration/python">
                    Enroll Now
                  </Link>
                </div>

                <div class="btn-part">
                  <Link class="readon learn-more learn-btn mt-4">Call 9669787936 for details and discounts</Link>
                </div>

                <div class="btn-part">
                  <Link class="mt-4" onClick={connectwhatsapp} target="_blank" style={{ backgroundColor: 'green', color: 'white', padding: '10px 20px', textDecoration: 'none', borderRadius: '5px', display: 'inline-block', textAlign: 'center' }}>
                    Connect on Whatsapp
                  </Link>
                </div>

                {/* new Code */}
                <div class="btn-part">
                  <Link class="readon started get-ready3 mt-4" to="/about">Click For Enrollment Process</Link>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="rs-services style3 modify2 pt-100 pb-100 md-pt-75 md-pb-80">
          <div class="container">
            <div class="sec-title2 text-center mb-45">
              {/* <span class="sub-text gold-color">Services</span> */}
              <h2 class="title title7">
                Python Batch Plan 2024
              </h2>
            </div>
            <div class="row">
              <div class="col-md-6 mb-20">
                <div class="services-item">
                  <div class="services-icon">
                    <div class="image-part">
                      <img class="main-img" src="../../assets/images/services/style2/main-img/1.png" alt="" />
                      <img class="hover-img" src="../../assets/images/services/style2/hover-img/1.png" alt="" />
                    </div>
                  </div>
                  <div class="services-content">
                    <div class="services-text">
                      <h3 class="title"><a href="web-development.html">Duration</a></h3>
                    </div>
                    <div class="services-desc">
                      <p>
                        4 Months Training and 2 Months Internship
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-20">
                <div class="services-item pink-bg">
                  <div class="services-icon">
                    <div class="image-part">
                      <img class="main-img" src="../../assets/images/services/style2/main-img/2.png" alt="" />
                      <img class="hover-img" src="../../assets/images/services/style2/hover-img/2.png" alt="" />
                    </div>
                  </div>
                  <div class="services-content">
                    <div class="services-text">
                      <h3 class="title"><a href="web-development.html">Sessions</a></h3>
                    </div>
                    <div class="services-desc">
                      <p>
                        Monday to Friday - Live Sessions, Mentor Session
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-20">
                <div class="services-item aqua-bg">
                  <div class="services-icon">
                    <div class="image-part">
                      <img class="main-img" src="../../assets/images/services/style2/main-img/3.png" alt="" />
                      <img class="hover-img" src="../../assets/images/services/style2/hover-img/3.png" alt="" />
                    </div>
                  </div>
                  <div class="services-content">
                    <div class="services-text">
                      <h3 class="title"><a href="web-development.html">Assignments and Tests</a></h3>
                    </div>
                    <div class="services-desc">
                      <p>
                        Weekly Assignments and Weekend Tests
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-20">
                <div class="services-item paste-bg">
                  <div class="services-icon">
                    <div class="image-part">
                      <img class="main-img" src="../../assets/images/services/style2/main-img/4.png" alt="" />
                      <img class="hover-img" src="../../assets/images/services/style2/hover-img/4.png" alt="" />
                    </div>
                  </div>
                  <div class="services-content">
                    <div class="services-text">
                      <h3 class="title"><a href="web-development.html">Technologies</a></h3>
                    </div>
                    <div class="services-desc">
                      <p>
                        Flask, DJango, JavaScript, React, Angular, Vue.js, HTML, CSS, MySQL, PostgreSQL, SQLite
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-md-6 sm-mb-20">
              <div class="services-item purple-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="../../assets/images/services/style2/main-img/5.png" alt="" />
                    <img class="hover-img" src="../../assets/images/services/style2/hover-img/5.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Placement Plan</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Starts from 7th month after 6 month classes
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="services-item green-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="../../assets/images/services/style2/main-img/6.png" alt="" />
                    <img class="hover-img" src="../../assets/images/services/style2/hover-img/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Soft Skills & HR Consulting</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Mock Interview, Soft Skills, Resume Building
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>


        <div class="rs-services style8 pt-120 pb-120 md-pt-80 md-pb-80">
          <div class="container">
            <div class="sec-title2 text-center mb-50">
              <h2 class="title testi-title">
                Python Batch Modules
              </h2>
              {/* <p class="desc desc3">
              Perspiciatis unde omnis iste natus error sit voluptatem accus antium doloremque laudantium, totam rem aperiam,
              eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            </p> */}
            </div>
            <div class="row align-items-center">
              <div class="col-lg-4 col-md-6 md-mb-30">
                <div class="services-item">
                  <div class="services-img">
                    <img src="../../assets/images/services/style10/1.png" alt="Services" />
                  </div>
                  <div class="services-content">
                    <div class="services-title">
                      <h3 class="title"> Module 1 - HTML, CSS, JS<br></br>1.5 Months</h3>
                    </div>
                    <p class="services-txt">
                      HTML, CSS, Bootstrap<br></br>
                      JavaScript<br></br>

                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 md-mb-30">
                <div class="services-item gray-light-bg">
                  <div class="services-img">
                    <img src="../../assets/images/services/style10/2.png" alt="Services" />
                  </div>
                  <div class="services-content">
                    <div class="services-title">
                      <h3 class="title">Module 2 - React, Angular, and Vue.js<br></br>1 Month</h3>
                    </div>
                    <p class="services-txt">
                      React<br></br>
                      Angular<br></br>
                      Vue.js<br></br>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="services-item pink-bg">
                  <div class="services-img">
                    <img src="../../assets/images/services/style10/3.png" alt="Services" />
                  </div>
                  <div class="services-content">
                    <div class="services-title">
                      <h3 class="title">Module 3 - Flask, DJango, FastAPI, DB<br></br>1.5 Months</h3>
                    </div>
                    <p class="services-txt">
                      Flask, DJango,FastAPI<br></br>
                      PostgreSQL, MySQL, and SQLite<br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="rs-services style8 pt-120 pb-120 md-pt-80 md-pb-80">
              <br></br>
            <div className="container">
              <div className="sec-title2 text-center mb-10">
                <LearningTestimonialVideos/>
              </div>
              {/* <div className="row align-items-center">
                {videos.map((video, index) => (
                  <div
                    key={index}
                    className="col-lg-4 col-md-6 md-mb-30"
                    style={{ marginBottom: "20px" }}
                  >
                    <YouTubeEmbed videoId={video.videoId} />
                  </div>
                ))}
              </div> */}
              {/* <div className="col-lg-6 pl-55 md-pl-15 md-pt-60">
                {!allLoaded && (
                  <div
                    className="btn-part"
                    style={{ position: "absolute", left: "33rem", top: "2rem" }}
                  >
                    <button
                      className="readon started get-new"
                      style={{ width: "175px" }}
                      onClick={loadMoreVideos}
                    >
                      Load More
                    </button>
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>
        </div>


        {/* <div className="row my-5">
            <div className="companies pb-5">
              <div className="companies-title">
                <h2>Our Corporate Training Program Glimpses</h2>
              </div>
            </div>

            <div className="companies-images">
              <Swiper
                slidesPerView={3}
                pagination={{
                  type: "fraction",
                }}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={image1} alt="Logo 1" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image2} alt="Logo 2" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image3} alt="Logo 3" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image4} alt="Logo 4" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image5} alt="Logo 5" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={image6} alt="Logo 6" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div> */}
        {/* <div className='row'>

            <div className='col-12'>

              <div class="container-1280px round-container">
                <div class="round-blocks-contain">
                  <div class="w-layout-grid round-column-grid">
                    <div class="round-block-wapper _1">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa495_laptop-code.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">Web Fundamentals (HTML, CSS &amp; JS)</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div><div class="round-block-wapper _2">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa497_lightbulb-on.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">React JS, Redux</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div></div><div class="w-layout-grid round-column-grid">
                    <div class="round-block-wapper _3">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa494_atom-simple%201.svg" loading="lazy" alt="" class="round-block-icon" />
                      <div class="paragraph-22px round-subheading-text">Node JS, Express</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div>
                    <div class="round-block-wapper _4">
                      <img src="https://assets-global.website-files.com/65159e844f8f08a72cefa2aa/65159e844f8f08a72cefa496_gears.svg" loading="lazy" alt="" class="round-block-icon extra" />
                      <div class="paragraph-22px round-subheading-text">MySQL, MongoDB</div>
                      <div class="paragraph-22px round-point-text">1 month</div>
                    </div>
                  </div>
                </div>
                <div class="round-text-wrapper"> */}
        {/* <h2 id="test" class="heading-48px round-heading">Become a MERN Full Stack Developer</h2> */}
        {/* <div class="round-check-list-container">
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">For college students</div></div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">4 months classroom program</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">2 months internship experience</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Start Date - 8th April</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Timing - Evening - 7-8pm</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Online Instructor led  Classes</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Projects based Learning</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">One-2-one mentorship / Doubt Sessions</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Training Certificate + Internship / Placement Opportunity</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Pay in monthly EMIs after classes</div>
                    </div>
                    <div class="round-check-list-row">
                      <img src={tickimage} loading="lazy" alt="" class="round-check-list-icon" />
                      <div class="paragraph-18px impossible-check-list-text">Total Fee (50% Discount) - <span style={{textDecoration: 'line-through', color: 'red'}}>10,000</span>&nbsp;5,000 </div>
                    </div>
                  </div>
                  <p className='text-primary'>New Batch starting from 22nd April 2024</p>

                  <Link to='/learning/registration/mern'><div class="btn btn-primary">
                    <div class="paragraph-18px colored-button-text">Enroll Now</div>
                  </div>
                  </Link> */}

        {/* <div class="button-black-overlay" >
                    </div>
                    <div class="colored-button-gradient-layer " >
                    </div> */}
        {/* <div class="colored-button-border-layer">
                    </div> */}
        {/* </div></div>
            </div>
          </div> */}
      </section>
    </>
  )
}

export default CoursePython;
