import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/img/hb_logo.png';

const Header = () => {

    const [mobilemenu, setmobilemenu] = useState(false);
    const [activeMenu, setActiveMenu] = useState('');

    const opencloseMobileMenu = () => {
        //console.log('openMobileMenu');
        setmobilemenu(mobilemenu => !mobilemenu);
    }

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        setmobilemenu(false);
        const path = pathname === '/' ? '/home' : pathname;
        setActiveMenu(path);

    }, [pathname]);



    const handleMenuClick = (menu) => {
        setActiveMenu(menu);

    };


    return (
        <>


            <div class="full-width-header">

                <header id="rs-header" class="rs-header style2 btn-style">

                    <div class="topbar-area style2">
                        <div class="container">
                            <div class="row y-middle">
                                <div class="col-lg-8">
                                    <ul class="topbar-contact">
                                        <li>
                                            <i class="flaticon-email"></i>
                                            <a href="mailto:info@hashedbit.com">info@hashedbit.com</a>
                                        </li>
                                        <li>
                                            <i class="flaticon-call"></i>
                                            <a href="#"> +91 - 9669787936</a>
                                        </li>
                                        <li>
                                            <i class="flaticon-location"></i>
                                            <a href="https://learning.hashedbit.com/" target='_blank'>Learning Login</a>
                                        </li>
                                        <li>
                                            <i class="flaticon-location"></i>
                                            Gurgaon
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-4 text-right">
                                    <div class="toolbar-sl-share">
                                        <ul>
                                            <li class="opening"> <em><i class="flaticon-clock"></i> 10 AM - 7 PM</em> </li>

                                            <li>
                                                <a class="icon-tilinkedin" href="https://www.linkedin.com/company/hashedbit-innovations/" target="_blank"><span><i class="fa fa-linkedin"></i></span></a>
                                            </li>
                                            <li>
                                                <a class="icon-tifacebook" href="https://www.facebook.com/hashedbitinnovations" target="_blank"><span><i class="fa fa-facebook"></i></span></a>
                                            </li>
                                            <li>
                                                <a class="icon-titwitter" href="https://twitter.com/HashedBit" target="_blank"><span><i class="fa fa-twitter"></i></span></a>
                                            </li>

                                            <li>
                                                <a class="icon-tiinstagram" href="https://www.instagram.com/hashedbit/" target="_blank"><span><i class="fa fa-instagram"></i></span></a>
                                            </li>
                                            <li>
                                                <a class="icon-tiinstagram" href="https://www.youtube.com/@HashedBit" target="_blank"><span><i class="fa fa-youtube"></i></span></a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="menu-area menu-sticky">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-3">
                                    <div class="logo-part">
                                        <Link to="/home"><img src={logo} alt="" /></Link>
                                    </div>
                                    <div class="mobile-menu">
                                        <a onClick={opencloseMobileMenu} className={mobilemenu ? 'rs-menu-toggle rs-menu-toggle-open secondary' : 'rs-menu-toggle rs-menu-toggle-close secondary'} class="rs-menu-toggle rs-menu-toggle-close secondary">
                                            <i class="fa fa-bars"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-lg-9 text-right">
                                    <div class="rs-menu-area">
                                        <div class="main-menu">
                                            <nav className={mobilemenu ? 'rs-menu pr-90 md-pr-0' : 'rs-menu pr-90 md-pr-0 rs-menu-close'} class="rs-menu" style={{ height: mobilemenu ? '300px' : '0px' }}>
                                                <ul class="nav-menu">
                                                    <li className={activeMenu === '/home' ? 'current-menu-item' : ''}>
                                                        <Link to="/home" onClick={() => handleMenuClick('/home')}>Home</Link>
                                                    </li>
                                                    {/* <li className={activeMenu === '/about' ? 'current-menu-item' : ''}>
                                                        <Link to="/about" onClick={() => handleMenuClick('/about')}>About</Link>
                                                    </li> */}

                                                    <li className={activeMenu.startsWith('/about') ? 'current-menu-item menu-item-has-children' : 'menu-item-has-children'}>
                                                        <Link to='/about' onClick={() => handleMenuClick('/about')}>About</Link>
                                                        <ul class="sub-menu">
                                                            <li><Link to="/about">About Us</Link> </li>
                                                            <li><Link to="/about/technology">Our Technologies</Link> </li>
                                                            <li><Link to="/about/domain">Our Domains</Link> </li>
                                                            <li><Link to="/about/portfolio">Portfolio</Link> </li>
                                                            <li><Link to="/about/testimonials">Testimonials</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li className={activeMenu.startsWith('/services') ? 'current-menu-item menu-item-has-children' : 'menu-item-has-children'}>
                                                        <Link to='/services' onClick={() => handleMenuClick('/services')}>Services</Link>
                                                        <ul class="sub-menu">
                                                            <li><Link to="#">Web Development</Link> </li>
                                                            <li><Link to="#">Digital Marketing</Link> </li>
                                                            <li><Link to="#">UI UX Development</Link> </li>
                                                            <li><Link to="#">IT Consulting</Link> </li>
                                                            <li><Link to="#">Software Development</Link></li>
                                                            <li><Link to="#">Mobile App Development</Link> </li>
                                                            <li><Link to="#">CMS and E-Commerce</Link> </li>
                                                            <li><Link to="#">ERP and CRM Development</Link> </li>
                                                            <li><Link to="#">Cloud and DevOps</Link></li>
                                                        </ul>
                                                    </li>

                                                    <li className={activeMenu.startsWith('/products') ? 'current-menu-item menu-item-has-children' : 'menu-item-has-children'}>
                                                        <Link to="/products" onClick={() => handleMenuClick('/products')}>Products</Link>

                                                        <ul class="sub-menu">
                                                            <li><Link to="/products/schoolmanagement">School Management</Link> </li>


                                                            {/* <li><Link to="/products/hospitalmanagement">Hospital Management</Link> </li> */}
                                                            <li><Link to="/products/hospitalmanagement">Hospital Management</Link> </li>
                                                            {/* <li><Link to="/products/employeemanagement">Employee Management</Link> </li> */}

                                                            {/* <li><Link to="/products/hospitalmanagement">Hospital Management</Link> </li> */}
                                                            {/* <li><Link to="/products/employeemanagement">Employee Management</Link> </li> */}
                                                            <li><Link to="/products/employeemanagement">Employee Management</Link> </li>


                                                            {/* <li><Link to="/products/hospitalmanagement">Hospital Management</Link> </li> */}
                                                            {/* <li><Link to="/products/employeemanagement">Employee Management</Link> </li> */}
                                                            {/* <li><Link to="/employeemanagement">Employee Management</Link> </li> */}

                                                            <li><Link to="/products/hotelmanagement">Hotel Management</Link></li>


                                                            <li><Link to="/products/learningmanagement">Learning Management</Link></li>
                                                            <li><Link to="/products/onlineexamination">Online Examination</Link></li>


                                                        </ul>
                                                    </li>

                                                    <li className={activeMenu.startsWith('/learning') ? 'current-menu-item menu-item-has-children' : 'menu-item-has-children'}>
                                                        <Link to='/learning' onClick={() => handleMenuClick('/learning')}>Learning</Link>
                                                        <ul class="sub-menu">
                                                            <li><Link to="/learning/internshipplacement">Internship cum Placement</Link> </li>
                                                            <li><Link to="/learning/mobile">Mobile App Development</Link> </li>
                                                            <li><Link to="/learning/mern">MERN Full Stack</Link> </li>
                                                            <li><Link to="/learning/python">Python Full Stack</Link> </li>
                                                            <li><Link to="/learning/Java">JAVA Full Stack</Link> </li>
                                                            <li><Link to="/learning/placement">Placement Batch</Link> </li>
                                                            <li><Link to="/learning/internship">Tech Internship</Link> </li>
                                                            <li><Link to="/learning/mbainternship">MBA Internship</Link></li>
                                                            <li><Link to="/learning/collegeinternship">College Internship</Link> </li>
                                                            <li><Link to="/learning/mockinterview">Mock Interview</Link> </li>
                                                            <li><Link to="/learning/collegeworkshop">Workshops</Link> </li>
                                                            <li><Link to="/learning/corporatetraining">Corporate Training</Link></li>
                                                            {/* <li><Link to="/learning/training/placement">Dynamic Learning</Link> </li> */}
                                                        </ul>
                                                    </li>
                                                    <li className={activeMenu.startsWith('/career') ? 'current-menu-item' : ''}>
                                                        <Link to='/career' onClick={() => handleMenuClick('/career')}>Career</Link>
                                                    </li>
                                                    {/* <li class="">
                                                        <Link to='/blogs'>Blog</Link>
                                                    </li> */}
                                                    <li className={activeMenu === '/blog' ? 'current-menu-item' : ''}>
                                                        <Link to="/blogs" onClick={() => handleMenuClick('/blogs')}>Blog</Link>
                                                    </li>
                                                    <li className={activeMenu.startsWith('/contact') ? 'current-menu-item' : ''}>
                                                        <Link to='/contact' onClick={() => handleMenuClick('/contact')}>Contact</Link>
                                                    </li>
                                                    {/* <li class="rs-mega-menu menu-item-has-children current-menu-item"> <a href="#">Home</a>
                                                        <ul class="mega-menu"> 
                                                            <li class="mega-menu-container">
                                                                <div class="mega-menu-innner">
                                                                    <div class="single-megamenu">
                                                                        <ul class="sub-menu">
                                                                            <li class="menu-title">Home Multipage</li>
                                                                            <li><a href="index.html">Main Demo</a></li>
                                                                            <li><a href="index2.html">Digital Agency 01</a></li>
                                                                            <li><a href="index3.html">IT Solution 01</a></li>
                                                                            <li><a href="index4.html">Digital Agency 02</a></li>
                                                                            <li><a href="index5.html">Software Solution</a></li>
                                                                            <li><a href="index6.html">Data Analysis</a></li>
                                                                            <li class="active last-item"><a href="index7.html">IT Solution 02</a></li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="single-megamenu">
                                                                        <ul class="sub-menu">
                                                                            <li class="menu-title">Home Multipage</li>
                                                                            <li><a href="index8.html">Gadgets Repairs</a></li>
                                                                            <li><a href="index9.html">Application Testing</a></li>
                                                                            <li><a href="index10.html">IT Solution 03</a></li>
                                                                            <li><a href="index11.html">Digital Agency Dark</a></li>
                                                                            <li><a href="index12.html">Web Design Agency</a></li>
                                                                            <li><a href="index13.html">Branding Agency</a></li>
                                                                            <li class="last-item"><a href="index14.html">Technology Agency</a></li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="single-megamenu">
                                                                        <ul class="sub-menu">
                                                                            <li class="menu-title">Home Onepage</li>
                                                                            <li><a href="onepage1.html">Main Demo</a></li>
                                                                            <li><a href="onepage2.html">Digital Agency 01</a></li>
                                                                            <li><a href="onepage3.html">IT Solution 01</a></li>
                                                                            <li><a href="onepage4.html">Digital Agency 02</a></li>
                                                                            <li><a href="onepage5.html">Software Solution</a></li>
                                                                            <li><a href="onepage6.html">Data Analysis</a></li>
                                                                            <li class="last-item"><a href="onepage7.html">IT Solution 02</a></li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="single-megamenu">
                                                                        <ul class="sub-menu">
                                                                            <li class="menu-title">Home Onepage</li>
                                                                            <li><a href="onepage8.html">Gadgets Repairs</a></li>
                                                                            <li><a href="onepage9.html">Application Testing</a></li>
                                                                            <li><a href="onepage10.html">IT Solution 03</a></li>
                                                                            <li><a href="onepage11.html">Digital Agency Dark</a></li>
                                                                            <li><a href="onepage12.html">Web Design Agency</a></li>
                                                                            <li><a href="onepage13.html">Branding Agency</a></li>
                                                                            <li class="last-item"><a href="onepage14.html">Technology Agency</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul> 
                                                    </li> */}
                                                    {/* <li>
                                                       <a href="about.html">About</a>
                                                   </li> */}
                                                    {/* <li class="menu-item-has-children">
                                                   <Link to='/services'>Services</Link>
                                                       <ul class="sub-menu">
                                                           <li><a href="software-development.html">Software Development</a> </li>
                                                           <li><a href="web-development.html">Web Development</a> </li>
                                                           <li><a href="analytic-solutions.html">Analytic Solutions</a> </li>
                                                           <li><a href="cloud-and-devops.html">Cloud and DevOps</a></li>
                                                           <li><a href="product-design.html">Product Design</a> </li>
                                                           <li><a href="data-center.html">Data Center</a> </li>
                                                       </ul>
                                                   </li> */}
                                                    {/* <li class="menu-item-has-children">
                                                       <a href="#">Pages</a>
                                                       <ul class="sub-menu">
                                                           <li class="menu-item-has-children">
                                                               <a href="#">Services</a>
                                                               <ul class="sub-menu">
                                                                  <li><a href="services1.html">Services 1</a></li>
                                                                  <li><a href="services2.html">Services 2</a></li>
                                                                  <li><a href="services3.html">Services 3</a></li>
                                                               </ul>
                                                           </li>
                                                           <li class="menu-item-has-children">
                                                              <a href="our-team.html">Our Team</a>
                                                           </li> 
                                                           <li class="menu-item-has-children">
                                                              <a href="single-team.html">Single Team</a>
                                                           </li>
                                                           <li class="menu-item-has-children">
                                                              <a href="#">Case Studies</a>
                                                               <ul class="sub-menu">
                                                                  <li><a href="case-studies-style1.html">Case Studies Style 1</a></li>
                                                                  <li><a href="case-studies-style2.html">Case Studies Style 2</a></li>
                                                                  <li><a href="case-studies-style3.html">Case Studies Style 3</a></li>
                                                                  <li><a href="case-studies-style4.html">Case Studies Style 4</a></li>
                                                                  <li><a href="case-studies-style5.html">Case Studies Style 5</a></li>
                                                                  <li><a href="case-studies-style6.html">Case Studies Style 6</a></li>
                                                                  <li><a href="case-studies-style7.html">Case Studies Style 7</a></li>
                                                               </ul>
                                                           </li>
                                                           <li class="menu-item-has-children">
                                                               <a href="shop.html">Shop</a>
                                                               <ul class="sub-menu">
                                                                   <li><a href="shop.html">Shop</a></li>
                                                                   <li><a href="shop-single.html">Shop Single</a></li>
                                                                   <li><a href="cart.html">Cart</a></li>
                                                                   <li><a href="checkout.html">Checkout</a></li>
                                                                   <li><a href="my-account.html">My Account</a></li>
                                                               </ul>
                                                           </li>
                                                           <li class="menu-item-has-children">
                                                              <a href="pricing.html">Pricing</a>
                                                           </li>
                                                           <li class="menu-item-has-children">
                                                              <a href="faq.html">Faq</a>
                                                           </li>
                                                            <li>
                                                               <a href="error.html">404</a>
                                                            </li>
                                                       </ul>
                                                   </li> */}
                                                    {/* <li class="menu-item-has-children">
                                                      <a href="blog.html">Blog</a>
                                                      <ul class="sub-menu">
                                                          <li><a href="blog.html">Blog</a> </li>
                                                          <li><a href="blog-details.html">Blog Details</a></li>
                                                      </ul>
                                                   </li> */}
                                                    {/* <li>
                                                      <a href="contact.html">Contact</a>
                                                   </li> */}
                                                </ul>
                                            </nav>
                                        </div>
                                        {/* <div class="expand-btn-inner search-icon hidden-md">
                                            <ul>
                                                <li class="sidebarmenu-search">
                                                    <a class="hidden-xs rs-search" data-target=".search-modal" data-toggle="modal" href="#">
                                                        <i class="flaticon-search"></i>
                                                    </a>
                                                </li>
                                                <li><a class="quote-btn" href="#">Get A Quote</a></li>
                                            </ul>
                                        </div>                                 */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </header>

                <nav class="right_menu_togle hidden-md">
                    <div class="close-btn"><span id="nav-close" class="text-center"><i class="fa fa-close"></i></span></div>
                    <div class="canvas-logo">
                        <a href="index.html"><img src="assets/images/logo-dark.png" alt="logo" /></a>
                    </div>
                    <div class="offcanvas-text">
                        <p>Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English.</p>
                    </div>
                    <div class="canvas-contact">
                        <h5 class="canvas-contact-title">Contact Info</h5>
                        <ul class="contact">
                            <li><i class="fa fa-globe"></i>Middle Badda, Dhaka, BD</li>
                            <li><i class="fa fa-phone"></i>+123445789</li>
                            <li><i class="fa fa-envelope"></i><a href="mailto:info@yourcompany.com">info@yourcompany.com</a></li>
                            <li><i class="fa fa-clock-o"></i>10:00 AM - 11:30 PM</li>
                        </ul>
                        <ul class="social">
                            <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                            <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </nav>

            </div>






        </>
    )
}

export default Header
