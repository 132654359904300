import React, {useEffect, useState} from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './customstyle.css';
import DataApp from './DataContext';
import axios from 'axios';
import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import Home from './components/Home';
import BrainTech from './BrainTech';
import About from './components/About';
import Blog from './components/Blog';
import BlogsCategory from './components/BlogsCategory';
import BlogDetail from './components/BlogDetail';
import BlogCareer from './components/BlogCareer';
import Career from './components/Career';
import Contact from './components/Contact';
import Learning from './components/Learning';
import Products from './components/Products';
import Services from './components/Services';
import Bde from './components/currrentOpeningDetailPages/Bde';
import CareerForm from './components/currrentOpeningDetailPages/CareerForm';
import JoinUsAsIntern from './components/currrentOpeningDetailPages/JoinUsAsIntern';
import WebDevelopment from './components/services/WebDevelopment';
import EmployeeManagement from './components/products/EmployeeManagement';
import HospitalManagement from './components/products/HospitalManagement';
import HotelManagement from './components/products/HotelManagement';
import SchoolManagement from './components/products/SchoolManagement';
import LearningManagement from './components/products/LearningManagement'
import OnlineExamination from './components/products/OnlineExamination';
import CampusAmbassador from './components/learning/CampusAmbassador';
import CollegeInternship from './components/learning/CollegeInternship';
import CollegeWorkshop from './components/learning/CollegeWorkshop';
import CorporateTraining from './components/learning/CorporateTraining';
import CourseInternship from './components/learning/CourseInternship';
import CourseMern from './components/learning/CourseMern';
import MobileAppDev from './components/learning/MobileAppDev';
import CoursePlacement from './components/learning/CoursePlacement';
import InternshipPlacement from './components/learning/InternshipPlacement';
import CourseRegistration from './components/learning/CourseRegistration';
import MBAInternship from './components/learning/MBAInternship';
import MBAPaidInternship from './components/learning/MBAPaidInternship';
import MockInterview from './components/learning/MockInterview';
import DynamicLearning from './components/learning/DynamicLearning';
import CourseJava from './components/learning/CourseJava';
import CoursePython from './components/learning/CoursePython';
import DueDate from './components/DueDateCalculator/DueDate'
import Verification from './components/learning/Verification';
import Testimonials from './components/about/Testimonials';
import Portfolio from './components/about/Portfolio';
import PrivacyPolicy from './components/compliance/PrivacyPolicy';
import TermsConditions from './components/compliance/TermsConditions';
import ShippingPolicy from './components/compliance/ShippingPolicy';
import RefundReturnPolicy from './components/compliance/RefundReturnPolicy';
import Store from './components/compliance/Store';
import Cart from './components/compliance/Cart';
import Checkout from './components/compliance/Checkout';
import Resources from './components/Resources';
import FD from './components/FdCalculator/FD';
import EMICalculator  from "./components/EmiCalculator/EMICalculator"

import { FaPython } from 'react-icons/fa';
import RD from './components/RdCalculator/RD';
import EnrollmentProcess from './components/learning/EnrollmentProcess';
import Technology from './components/about/Technology';
import Domain from './components/about/Domain';


function App() {

  //temp code to keep server live
  // const callApiCategories = async () => {
  //   try {
  //     const url = process.env.REACT_APP_API_URL + 'category/fetchallcategories';
  //     const response = await axios.get(url);
  //   }
  //   catch (error) { console.log(error); }
  // }
  // useEffect(() => {
  //   setInterval(() => callApiCategories(), 10000)
  // }, [])
  //temp code to keep server live

  return (
    <>
      <BrowserRouter>
        <DataApp>

          {/* <div id="loader" class="loader">
            <div class="loader-container"></div>
        </div> */}

          <div class="main-content">
            <Header />
            <Routes>
              <Route path='/home' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/services' element={<Services />} />
              <Route path='/services/webdevelopment' element={<WebDevelopment/>}/>


              <Route path='/products' element={<Products />} />
              <Route path='/products/employeemanagement' element={<EmployeeManagement />} />
              {/* <Route path='/employeemanagement' element={<EmployeeManagement />} /> */}
              <Route path='/products/hotelmanagement' element={<HotelManagement />} />
              <Route path='/products/schoolmanagement' element={<SchoolManagement />} />
              <Route path='/products/hospitalmanagement' element={<HospitalManagement />} />
              <Route path='/products/learningmanagement' element={<LearningManagement />} />
              <Route path='/products/onlineexamination' element={<OnlineExamination />} />

              {/* <Route path='/hospitalmanagement' element={<HospitalManagement />} /> */}

              <Route path='/contact' element={<Contact />} />
              <Route path='/career' element={<Career />} />
              <Route path='/career/currentOpening/bde' element={<Bde />} />
              <Route path='/career/apply' element={< CareerForm/>} />
              <Route path='/career/currentOpening/joinusasintern' element={< JoinUsAsIntern/>} />
              <Route path='/blogs' element={<Blog />} />
              <Route path='/blog/:blogid' element={<BlogDetail />} />
              <Route path='/blogs/category/:categoryname' element={<BlogsCategory />} />
              <Route path='/jobs' element={<BlogCareer />} />
              <Route path='/learning' element={<Learning />} />
              <Route path="/about/testimonials" element={<Testimonials />} />

              <Route path="/about/portfolio" element={<Portfolio />} />
                
              <Route path="/about/technology" element={<Technology />} />
              <Route path="/about/domain" element={<Domain />} />

              {/* to be developed */}
              <Route path='/learning/internship' element={<CourseInternship />} />
              <Route path='/learning/Java' element={<CourseJava />} />
              <Route path='/learning/python' element={<CoursePython />} />
              <Route path='/learning/mbainternship' element={<MBAInternship />} />
              <Route path='/learning/mbapaidinternship' element={<MBAPaidInternship />} />
              <Route path='/learning/mobile' element={<MobileAppDev />} />
              <Route path='/learning/mern' element={<CourseMern />} />
              <Route path='/learning/placement' element={<CoursePlacement />} />
              <Route path='/learning/internshipplacement' element={<InternshipPlacement />} />
              <Route path='/learning/mockinterview' element={<MockInterview />} />
              <Route path='/learning/collegeinternship' element={<CollegeInternship />} />
              <Route path='/learning/corporatetraining' element={<CorporateTraining />} />
              <Route path='/learning/collegeworkshop' element={<CollegeWorkshop />} />
              <Route path='/learning/campusambassador' element={<CampusAmbassador />} />
              <Route path='/learning/:learning_type/:course_name' element={<DynamicLearning />} />
              <Route path='/learning/registration/:coursename' element={<CourseRegistration />} />
              <Route path='/verification' element={<Verification />} />
              <Route path='/privacypolicy' element={<PrivacyPolicy />} />
              <Route path='/termsconditions' element={<TermsConditions />} />
              <Route path='/shippingpolicy' element={<ShippingPolicy />} />
              <Route path='/refundreturnpolicy' element={<RefundReturnPolicy />} />
              <Route path='/store' element={<Store />} />
              <Route path='/cart' element={<Cart />} />
              <Route path='/checkout' element={<Checkout />} />
              <Route path='/resources' element={<Resources />} />
              {/* to be developed */}
              <Route path='fd-calculator' element={<FD/>}/>
              <Route path='emi-calculator' element={<EMICalculator />}/>

              <Route path='rd-calculator' element={<RD/>}/>
              <Route path='due-date-calculator' element={<DueDate/>}/>
              <Route path='process' element={<EnrollmentProcess/>}/> 
              <Route path='/braintech' element={<BrainTech />} />
              <Route path='/' element={<Home />} />
            </Routes>
          </div>
          <Footer />
        </DataApp>

      </BrowserRouter>
    </>
  );
}

export default App;
